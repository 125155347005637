<template>
  <div class="app-container">
    <div class="head-container">
      <quick-select ref="shops" v-model="query.shopId" filterable url="api/shop" placeholder="选择门店" display-field="shopName" class="filter-item" @change="toQuery" @inited="init" style="width: 320px;" autoSelectFirstOption/>
      <!-- <el-tree ref="shopData" :data="shops" :props="defaultProps" :expand-on-click-node="false" default-expand-all @node-click="handleNodeClick" :highlight-current="true" /> -->
      <el-select v-model="query.enabled" filterable clearable placeholder="状态" class="filter-item" style="width: 90px" @change="toQuery">
        <el-option :value="true" label="激活" />
        <el-option :value="false" label="禁用" />
      </el-select>
      <el-input v-model="query.blurry" clearable placeholder="输入名称或者邮箱搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <div style="display: inline-block;margin: 0px 2px;">
        <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
      </div>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>

    <el-table v-loading="loading" row-key="id" :data="data" size="small" style="width: 100%;">
      <el-table-column prop="erpCode" label="ERP编码" v-if="entType === 'serta'" min-width="130" />
      <el-table-column prop="username" label="用户名" min-width="120" />
      <el-table-column prop="userRealName" label="姓名" min-width="120" />
      <el-table-column prop="phone" label="电话" min-width="160" />
      <!-- <el-table-column prop="email" label="邮箱" min-width="160" /> -->
      <el-table-column label="角色" :formatter="r=>{return showRoles(r.roles)}" />
      <el-table-column prop="email" label="所属门店" :formatter="r=>{return shopName(r.shopIds)}" min-width="320" />
      <el-table-column label="状态" align="center" width="80">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.enabled">激活</el-tag>
          <el-tag type="info" v-else>禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建日期" width="160">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>

      <el-table-column width="150" align="center" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
          <el-button size="mini" type="text" @click="resetPwd(scope.row)" v-if="scope.row.enabled">账号</el-button>
          <el-popover :ref="scope.row.id" placement="top" width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
          </el-popover>
        </div>
      </el-table-column>
    </el-table>

    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <eForm ref="form" :is-add="isAdd" />
    <reset-pwd ref="pwd" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { del, downloadUser } from "@/api/shopUser";
import { getDepts } from "@/api/dept";
import { parseTime, downloadFile } from "@/utils/index";
import { getStatementType } from "@/api/capitalPool";
import eForm from "./form";
import request from "@/utils/request";
import resetPwd from "../../system/user/reset-pwd";

export default {
  name: "User",
  components: { eForm, resetPwd },
  mixins: [initData],
  data() {
    return {
      isAdd: false,
      delLoading: false,
      loading: false,
      deptName: "",
      shops: [],
      entType: null,
      shopId: null,
      downloadLoading: false,
      query: {
        shopId: null,
        enabled: null,
        blurry: null,
      },
    };
  },
  mounted: function () {
    getStatementType().then((res) => {
      this.entType = res;
    });
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      // if (!this.query.shopId) {
      //   this.data = [];
      //   return false;
      // } else {
        this.url = "api/shop/users";
        this.params = {
          sort: "username,asc",
          blurry: this.query.blurry,
          enabled: this.query.enabled,
          shopId: this.query.shopId,
        };
        return true;
      // }
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          console.log(err.response.data.message);
        });
    },
    handleNodeClick(data) {
      this.shopId = data.id;
      this.init();
    },
    add() {
      this.isAdd = true;
      this.$refs.form.resetForm(this.shopId ? [this.shopId] : []);
    },
    edit(data) {
      this.isAdd = false;
      let fd = JSON.parse(JSON.stringify(data));
      fd._roleIds = data.roles.map((r) => {
        return r.id;
      });
      fd.shopId = fd.shopId.replace(/(^,)|(,$)/gi, "");
      this.$refs.form && this.$refs.form.resetForm(null, fd);
    },
    shopName(ids) {
      if(!ids) return "";
      if (this.$refs.shops) {
        return ids
          .map((id) => {
            return this.$refs.shops.getDisplayText(id);
          })
          .join("，");
      }
      return "";
    },
    resetPwd(data) {
      this.$refs.pwd && this.$refs.pwd.resetForm(data.username);
    },
    showRoles(data){
      let n="";
      if(data.length>1){
        data.forEach(o => {
          n+=o.name+",";
        });
      }else if(data.length==1){
        data.forEach(o => {
          n=o.name;
        });
      }
      return n;
    },
  },
};
</script>